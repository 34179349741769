import React from "react";
import { Link } from "react-router-dom";
import twitter from "../../assets/images/twitter.png";
import facebook from "../../assets/images/facebook.png";
import linkin from "../../assets/images/linkin.png";
import polygonFooter from "../../assets/images/polygon-footer.png";
export default function Footer() {
  return (
    <footer className="bg-dark-primary">
      <div className="bg-dark-secondary text-white py-12 text-center flex flex-col contact text-xs tablet:text-base">
        <div className="flex flex-row justify-between items-center">
          <div className="mobile:hidden tablet:block">
            <img src={polygonFooter} alt="" className="scale-75" />
          </div>
          <div className="w-full text-base desktop-xl:text-xl">
            <h2 className="font-bold ">Contact</h2>
            <h2 className="font-bold ">Tech Thailand Advocates</h2>
            {/* <h4 className="mt-2 ">Tel: 02-001-5375</h4> */}
            <h4 className="mt-2 ">Email: techthailandadvocates@techsauce.co</h4>
            <div className="flex flex-row justify-center items-center mt-4">
              <Link
                to="https://www.facebook.com/techthailandadvocates"
                target={"_blank"}
              >
                <img src={facebook} alt="" className="scale-50" />
              </Link>
              <Link to="https://twitter.com/TechThailandAdv" target={"_blank"}>
                <img src={twitter} alt="" className="scale-50" />
              </Link>
              <Link to=" https://www.linkedin.com/showcase/tech-thailand-advocates" target={"_blank"}>
                <img src={linkin} alt="" className="scale-50" />
              </Link>
             
            </div>
          </div>
          <div className="mobile:hidden tablet:block">
            <img
              src={polygonFooter}
              alt=""
              className="rotate-[180deg] scale-75 "
            />
          </div>
        </div>
      </div>
      <div className="bg-dark-primary text-white text-right px-8 py-4 mobile:text-xs tablet:text-sm">
        <a href="/privacy">Privacy Policy</a>
      </div>
    </footer>
  );
}
