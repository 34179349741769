import React from "react";
import Footer from "../../components/footer/footer";
import techsauceLogo from "../../assets/images/partner/tech-logo.png";
import thaiAccLogo from "../../assets/images/partner/thai-acc-logo.png";
import globaltechLogo from "../../assets/images/partner/global-tech-logo.png";
import oranuch from "../../assets/images/team/Oranuch-Lerdsuwankij.png";
import chidapa from "../../assets/images/team/Chidapa.jpg";
import facebook from "../../assets/images/facebook-orange.png";
import twitter from "../../assets/images/twitter-orange.png";
import linkin from "../../assets/images/linkin-orange.png";
import redPolygonfill from "../../assets/images/polygon/red-polygon-fill.png";
import bluePolygonfill from "../../assets/images/polygon/blue-polygon-fill.png";
import bluePolygonfill2 from "../../assets/images/polygon/blue-polygon-fill-2.png";
import whitePolygonfill from "../../assets/images/polygon/white-polygon-fill.png";
import communicate from "../../assets/images/communicate.png";
import collab from "../../assets/images/collab.png";
import connect from "../../assets/images/connect.png";
import doublePolygon from "../../assets/images/polygon/double-polygon.png";
import greenPolygon from "../../assets/images/polygon/green-polygon-fill.png";
import transparentPolygon from "../../assets/images/polygon/transparent-polygon-fill.png";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./home-page.css";
export default function HomePage() {
  return (
    <div className="bg-homepage">
      <div
        id="home-page"
        className="bg-transparent mobile:h-[100dvh] tablet:h-[100vh] overflow-scroll scroll-smooth"
      >
        <section
          id="hero"
          className="min-h-[100vh] bg-white mobile:mt-[5.3rem] tablet:mt-[4.6rem] bg-hero"
        >
          <div className="grid mobile:grid-cols-1 tablet:grid-cols-12  h-[100vh]">
            <div className="tablet:col-span-5 bg-orange-primary bg-white-polygon-pol p-12 flex flex-col justify-center h-[100vh]">
              <h1 className="text-3xl tablet:text-2xl laptop:text-4xl desktop-xl:text-6xl font-bold text-blue-primary">
                The voice of
                <br />
                tech experts
                <br />
                and professionals
                <br /> in Thailand
              </h1>
              <p className="text-white mt-4 desktop-xl:text-2xl">
                Tech Thailand Advocates is part of an international
                network,Global
                <br className="hidden desktop-l:block" /> Tech Advocates, which
                brings together more than 30,000 private
                <br className="hidden desktop-l:block" /> sector leaders,
                experts, and investors.
              </p>
              <a
                href="/become-and-advocate"
                className="bg-blue-primary text-base desktop-xl:text-2xl text-white w-64 desktop-xl:w-96 h-12 p-8 flex justify-center items-center mt-4"
              >
                Become an advocate
              </a>
            </div>
            <div className="hidden tablet:block  tablet:col-span-7 h-full">
              <div className="flex flex-row h-[100vh] w-full">
                <div className="w-[33.33%]  border-r-8 border-r-white">
                  <div className="flex items-end h-full">
                    <img src={bluePolygonfill2} alt="" className="scale-100" />
                  </div>
                </div>
                <div className="w-[33.33%]  border-r-8 border-r-white">
                  <div className="flex items-center h-full">
                    <img src={greenPolygon} alt="" />
                  </div>
                </div>
                <div className="w-[33.33%] ">
                  <div>
                    <img src={transparentPolygon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="about"
          className="tablet:min-h-[75vh] bg-white flex flex-col justify-center items-center"
        >
          <div className="grid mobile:grid-cols-1 tablet:grid-cols-12 tablet:min-h-[75vh]">
            <div className="col-span-7 p-8 flex flex-col justify-center">
              <div>
                <h1 className="text-orange-primary font-bold text-2xl tablet:text-2xl desktop-xl:text-4xl">
                  WHO WE ARE
                </h1>
                <h2 className="mt-4 text-lg desktop-xl:text-xl font-bold text-blue-primary">
                  About Tech Thailand Advocates
                </h2>
                <p className="text-base desktop-xl:text-lg">
                  Tech Thailand Advocates is led by Oranuch Lerdsuwankij, CEO
                  and Founder of Techsauce Media Co., Ltd. Her mission has been
                  to develop Thailand's platforms and ecosystem. This is to
                  connect Thai startups, large corporations, and investors with
                  global and innovation tech hubs over the past 10 years through
                  various ways. The project represents another important step
                  towards elevating the Thai ecosystem.
                </p>
                <br />
                <hr className="h-3 border bg-orange-primary border-orange-primary mobile:hidden tablet:block tablet:w-72 " />
                <br />
                <h2 className="font-bold text-lg desktop-xl:text-xl  text-blue-primary">
                  About Global Tech Advocates
                </h2>
                <p className="text-base desktop-xl:text-lg">
                  Global Tech Advocates is a non-profit organization designed to
                  address the challenges facing society through its
                  international network of technology communities. The
                  organization brings together more than 30,000 tech leaders,
                  experts, and investors from 30 countries around the world.
                  Founded by Russ Shaw, a global advocate for technology
                  leaders. The organization supports tech ecosystems and
                  campaigns to help address the challenges that companies in the
                  tech industry face.
                </p>
              </div>
            </div>
            <div className="col-span-5 bg-about hidden tablet:block ">
              <div className="flex justify-end -mt-32 pr-8">
                <img src={doublePolygon} alt="" className=" scale-[0.86]" />
              </div>
            </div>
          </div>
        </section>
        <section
          id="card"
          className="min-h-[60vh] bg-dark-primary/75 tablet:flex tablet:flex-col tablet:justify-center tablet:items-center"
        >
          <div className="hidden tablet:flex flex-col mobile:items-center  tablet:flex-row gap-6 p-4 tablet:p-4 laptop:gap-8 laptop:p-8">
            <div className="bg-white text-center p-8 mobile:w-72 mobile:h-80 tablet:w-56  tablet:h-[25rem] laptop:w-[18rem] laptop:h-[19rem] desktop:w-[24rem] desktop:h-[17rem]">
              <div className="flex flex-row justify-center">
                <img src={connect} alt="" />
              </div>
              <h1 className=" text-orange-primary text-xl font-bold mt-4">
                Connect
              </h1>
              <p className="mt-4">
                Through our Global Tech Advocates family, our members are
                connected to other global tech hubs.
              </p>
            </div>
            <div className="bg-white text-center p-8 mobile:w-72 mobile:h-80 tablet:w-56  tablet:h-[25rem] laptop:w-[18rem] laptop:h-[19rem] desktop:w-[24rem] desktop:h-[17rem]">
              <div className="flex flex-row justify-center">
                <img src={communicate} alt="" />
              </div>
              <h1 className=" text-orange-primary text-xl font-bold mt-4">
                Communicate
              </h1>
              <p className="mt-4">
                We produce and distribute content with a high added value to
                protect and promote the interests of the Thai tech industry as
                well as carry its voice.
              </p>
            </div>
            <div className="bg-white text-center p-8 mobile:w-72 mobile:h-80 tablet:w-56  tablet:h-[25rem] laptop:w-[18rem] laptop:h-[19rem] desktop:w-[24rem] desktop:h-[17rem]">
              <div className="flex flex-row justify-center">
                <img src={collab} alt="" />
              </div>
              <h1 className=" text-orange-primary text-xl font-bold mt-4">
                Collaborate
              </h1>
              <p className="mt-4">
                Encourage collaboration between the private sector and the
                research community, as well as between startups and large
                organizations.
              </p>
            </div>
          </div>
          <div className="tablet:hidden flex justify-center">
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true, dynamicBullets: true }}
              className="mt-8"
            >
              <SwiperSlide>
                <div className="bg-white text-center p-8 mobile:w-72 mobile:h-80 tablet:w-56  tablet:h-[25rem] laptop:w-[18rem] laptop:h-[19rem] desktop:w-[24rem] desktop:h-[17rem]">
                  <div className="flex flex-row justify-center">
                    <img src={connect} alt="" />
                  </div>
                  <h1 className=" text-orange-primary text-xl font-bold mt-4">
                    Connect
                  </h1>
                  <p className="mt-4">
                    Through our Global Tech Advocates family, our members are
                    connected to other global tech hubs.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white text-center p-8 mobile:w-72 mobile:h-80 tablet:w-56  tablet:h-[25rem] laptop:w-[18rem] laptop:h-[19rem] desktop:w-[24rem] desktop:h-[17rem]">
                  <div className="flex flex-row justify-center">
                    <img src={communicate} alt="" />
                  </div>
                  <h1 className=" text-orange-primary text-xl font-bold mt-4">
                    Communicate
                  </h1>
                  <p className="mt-4">
                    We produce and distribute content with a high added value to
                    protect and promote the interests of the Thai tech industry
                    as well as carry its voice.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white text-center p-8 mobile:w-72 mobile:h-80 tablet:w-56  tablet:h-[25rem] laptop:w-[18rem] laptop:h-[19rem] desktop:w-[24rem] desktop:h-[17rem]">
                  <div className="flex flex-row justify-center">
                    <img src={collab} alt="" />
                  </div>
                  <h1 className=" text-orange-primary text-xl font-bold mt-4">
                    Collaborate
                  </h1>
                  <p className="mt-4">
                    Encourage collaboration between the private sector and the
                    research community, as well as between startups and large
                    organizations.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <section
          id="vision"
          className=" bg-white min-h-[40vh] tablet:min-h-[65vh]"
        >
          <div className="flex flex-row min-h-[30vh] tablet:min-h-[65vh]">
            <div className="bg-white  tablet:w-1/2 p-8 tablet:flex flex-col items-center justify-center">
              <h1 className="font-bold text-start w-full text-xl tablet:text-3xl desktop-xl:text-4xl text-blue-primary">
                Our Vision
              </h1>
              <p className="text-base desktop-xl:text-xl mt-4">
                As a part of the Global Tech Advocates international network,
                Tech Thailand Advocates works to connect startups and large
                corporations with investors, in addition to facilitating
                connections between those organizations and other international
                networks.
              </p>
            </div>
            <div className="w-1/2  hidden tablet:block bg-vision">
              <div className="h-[65vh] flex items-center sticky">
                <div>
                  <img src={whitePolygonfill} alt="" className="" />

                  <img src={bluePolygonfill} alt="" className=" -mt-36 ml-3" />
                  <img src={redPolygonfill} alt="" className=" -mt-[6.5rem] " />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="present"
          className="min-h-[70vh] bg-toxic  text-white font-bold"
        >
          <div className=" bg-dark-primary/50 min-h-[70vh]  flex flex-col items-center justify-center bg-polygon-present">
            <div className="flex flex-row justify-between items-center  h-full">
              <div className="text-center  mobile:p-8 tablet:p-28 tablet:text-base laptop:text-xl  desktop-xl:text-2xl">
                <p>
                  <b className="font-bold tablet:text-3xl laptop:text-5xl">"</b>
                  At present the world is facing many challenges, whether they
                  be economic, social, or environmental problems. There is a
                  sense of urgency to solve these problems. By connecting with
                  an international network such as Global Tech Advocates,
                  facilitation from the organization can help enhance the
                  collaboration between the Thai tech community with 30
                  communities across the world. Priority will be given to
                  technology such as ClimateTech, which is part of GTA’s Tech
                  for Net Zero Hub to exchange knowledge, promote Thai startups
                  using technology to address climate change on the global
                  stage, and to drive Thailand’s investment growth.
                  <b className="font-bold tablet:text-3xl laptop:text-5xl">"</b>
                </p>
                <p className="mt-8 font-extralight">
                  Oranuch Lerdsuwankij, CEO & Founder of Techsauce Media and
                  Founder of Tech Thailand Advocates
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id="news"
          className="bg-white px-8 py-12 tablet:py-16 tablet:px-12 mobile:min-h-[35vh] tablet:min-h-[40vh]"
        >
          <div className="hidden tablet:flex flex-row flex-wrap justify-center items-center min-h-[40vh]">
            <div className="tablet:w-1/2  flex flex-col justify-center items-center">
              <div>
                <h2 className=" tablet:text-3xl laptop:text-4xl desktop-xl:text-6xl">
                  For news update of <br />
                  Tech Thailand <br />
                  Advocate
                </h2>
                <p className="text-base desktop-xl:text-2xl mt-4">
                  please visit our social media platform at
                </p>
              </div>
            </div>
            <div className="tablet:w-1/2  flex justify-center items-center">
              <div className="flex flex-col gap-4">
                <a
                  href="https://www.facebook.com/techthailandadvocates"
                  className="flex flex-row justify-start items-center gap-4 hover:text-orange-primary transition-colors"
                >
                  <div className="w-[60px] h-[50px] desktop-xl:w-[80px] desktop-xl:h-[70px] flex justify-center">
                    <img src={facebook} alt="" className="scale-75 " />
                  </div>
                  <h2 className="desktop-xl:text-2xl">techthailandadvocates</h2>
                </a>
                <a
                  href="https://twitter.com/TechThailandAdv"
                  className="flex flex-row justify-start items-center gap-4 hover:text-orange-primary transition-colors"
                >
                  <img
                    src={twitter}
                    alt=""
                    className="scale-75 w-[60px] h-[50px] desktop-xl:w-[80px] desktop-xl:h-[70px]"
                  />
                  <h2 className="desktop-xl:text-2xl">TechThailandAdv</h2>
                </a>
                <a
                  href="https://www.linkedin.com/showcase/tech-thailand-advocates"
                  className="flex flex-row justify-start items-center gap-4 hover:text-orange-primary transition-colors"
                >
                  <img
                    src={linkin}
                    alt=""
                    className="scale-75 w-[60px] h-[50px] desktop-xl:w-[80px] desktop-xl:h-[70px]"
                  />
                  <h2 className="desktop-xl:text-2xl">
                    Tech Thailand Advocates
                  </h2>
                </a>
              </div>
            </div>
          </div>
          <div className="tablet:hidden text-center flex flex-col justify-center items-center min-h-[35vh]">
            <h2 className="text-xl font-bold">
              For news update of Tech Thailand Advocate
            </h2>
            <p className="mt-4">please visit our social media platform at</p>
            <div className="flex flex-col  gap-4 mt-4">
              <a
                href="https://www.facebook.com/techthailandadvocates"
                className="flex flex-row justify-start items-center gap-4 w-full hover:text-orange-primary transition-colors"
              >
                <div className="w-[40px] h-[40px] flex justify-center">
                  <img src={facebook} alt="" className="scale-90 " />
                </div>
                <h2>techthailandadvocates</h2>
              </a>
              <a
                href="https://twitter.com/TechThailandAdv"
                className="flex flex-row justify-start items-center gap-4 hover:text-orange-primary transition-colors"
              >
                <img
                  src={twitter}
                  alt=""
                  className="scale-75 w-[40px] h-[30px]"
                />
                <h2>TechThailandAdv</h2>
              </a>
              <a
                href="https://www.linkedin.com/showcase/tech-thailand-advocates"
                className="flex flex-row justify-start items-center gap-4 hover:text-orange-primary transition-colors"
              >
                <img
                  src={linkin}
                  alt=""
                  className="scale-75 w-[40px] h-[30px]"
                />
                <h2>Tech Thailand Advocates</h2>
              </a>
            </div>
          </div>
        </section>
        <section
          id="team"
          className="  flex flex-col tablet:block mobile:py-8 tablet:py-8  justify-center mobile:px-0 tablet:px-12 bg-dark-secondary text-white"
        >
          <h2 className="font-bold text-lg desktop-xl:text-xl mobile:ml-8 tablet:ml-0">
            Our Team
          </h2>
          {/* desktop */}
          <div className="hidden  w-full  laptop:flex flex-row justify-center items-center gap-8  h-[400px]  ">
            <div
              id="oranuch"
              className="flex flex-col justify-center items-center group/item hover:bg-orange-primary hover:w-[32rem] desktop-xl:hover:w-[42rem]  hover:h-[20rem] desktop-xl:hover:h-[25rem]  hover:justify-start hover:items-start transition duration-[450ms] transform origin-top ease-in-out hover:scale-105"
            >
              <div className="flex flex-col justify-center items-center group-hover/item:items-start group-hover/item:p-4">
                <div className=" w-[150px] laptop:w-[185px] desktop-xl:w-[250px] group-hover/item:w-[90px] group-hover/item:h-[90px]">
                  <img
                    src={oranuch}
                    alt=""
                    className="group-hover/item:rounded-[50%]"
                  />
                </div>
                <h3 className="font-bold mt-4 group-hover/item:hidden text-center">
                  Oranuch Lerdsuwankij
                </h3>
                <div className="hidden group-hover/item:block mt-4 text-[12px] desktop-xl:text-base">
                  <b>
                    Oranuch Lerdsuwankij, CEO and Founder of Techsauce Media
                  </b>
                  <p>
                    Oranuch Lerdsuwankij is the CEO and co-founder of Techsauce,
                    a leading technology/innovation community platform in
                    Thailand. It also organizes Southeast Asia's largest
                    technology conference, Techsauce Global Summit. In addition
                    to being a board member of Thailand's National Electronics
                    and Computer Technology Center (NECTEC), she is recognized
                    as one of the pioneers of the Thailand tech startup
                    ecosystem. Aside from her success in the technology
                    industry, she is also interested in enhancing technology's
                    use in helping society. This includes global challenges,
                    including issues such as inclusion, social impact, climate
                    change, and many others.
                  </p>
                </div>
                <p className="group-hover/item:hidden text-center w-[320px] max-h-[48px]">
                  Co-Founder & CEO of Techsauce Media
                  <br />
                  Founder of Tech Thailand Advocates
                </p>
                {/* <p className="group-hover/item:hidden">Techsauce Media</p> */}
              </div>
            </div>
            <div
              id="chidapa"
              className="flex flex-col justify-center items-center group/item  hover:bg-orange-primary hover:w-[32rem] desktop-xl:hover:w-[42rem]  hover:h-[20rem] desktop-xl:hover:h-[25rem] hover:justify-start hover:items-start transition duration-[450ms] transform origin-top ease-in-out hover:scale-105"
            >
              <div className="flex flex-col justify-center items-center group-hover/item:items-start group-hover/item:p-4">
                <div className=" w-[150px] laptop:w-[185px]  desktop-xl:w-[250px]  group-hover/item:w-[90px] group-hover/item:h-[90px]">
                  <img
                    src={chidapa}
                    alt=""
                    className="group-hover/item:rounded-[50%]"
                  />
                </div>
                <h3 className="font-bold mt-4 text-center">Chidapa Chanthong </h3>
                <p className="hidden group-hover/item:block text-[12px] desktop-xl:text-base">
                As the Director of Global Business Development & Expansion at Techsauce, I'm a passionate leader driving Techsauce's mission to ignite technological and entrepreneurial progress in Southeast Asia. We connect innovators, business leaders, ecosystem builders, and policymakers through educational resources, community building, and strategic partnerships.
                </p>
                <p className="group-hover/item:hidden  text-center w-[320px] max-h-[48px]">
                Director of Global Business Development & Expansion
                  <br /> Techsauce 
                </p>
                {/* <p className="group-hover/item:hidden text-center">Development Manager</p> */}
              </div>
            </div>

          </div>
          {/* mobile */}
          <div className="laptop:hidden flex flex-col justify-center items-center gap-8 mt-8">
            <div
              id="oranuch-mobile"
              className="flex flex-col justify-center items-center mx-8 group/item hover:mb-8 hover:bg-orange-primary hover:min-w-[15rem] hover:min-h-[28rem] desktop-xl:hover:h-[25rem] hover:justify-start hover:items-start transition duration-300 transform origin-top ease-in-out hover:scale-105"
            >
              <div className="flex flex-col justify-center items-center group-hover/item:items-start group-hover/item:p-4">
                <div className=" w-[150px] laptop:w-[185px] desktop-xl:w-[250px] group-hover/item:w-[90px] group-hover/item:h-[90px]">
                  <img
                    src={oranuch}
                    alt=""
                    className="group-hover/item:rounded-[50%]"
                  />
                </div>
                <h3 className="font-bold mt-4 group-hover/item:hidden text-center text-base">
                  Oranuch Lerdsuwankij
                </h3>
                <div className="hidden group-hover/item:block mt-4 text-[12px] desktop-xl:text-base">
                  <b>
                    Oranuch Lerdsuwankij, CEO and Founder of Techsauce Media
                  </b>
                  <p>
                    Oranuch Lerdsuwankij is the CEO and co-founder of Techsauce,
                    a leading technology/innovation community platform in
                    Thailand. It also organizes Southeast Asia's largest
                    technology conference, Techsauce Global Summit. In addition
                    to being a board member of Thailand's National Electronics
                    and Computer Technology Center (NECTEC), she is recognized
                    as one of the pioneers of the Thailand tech startup
                    ecosystem. Aside from her success in the technology
                    industry, she is also interested in enhancing technology's
                    use in helping society. This includes global challenges,
                    including issues such as inclusion, social impact, climate
                    change, and many others.
                  </p>
                </div>
                <p className="group-hover/item:hidden text-center">
                  {" "}
                  Co-Founder & CEO of Techsauce Media
                </p>
                <p className="group-hover/item:hidden">
                  Founder of Tech Thailand Advocates
                </p>
              </div>
            </div>
            <div
              id="chidapa-mobile"
              className="flex flex-col justify-center items-center mx-8 group/item  hover:mb-8 hover:bg-orange-primary hover:min-w-[15rem] hover:min-h-[24rem] desktop-xl:hover:h-[25rem] hover:justify-start hover:items-start transition duration-300 transform origin-top ease-in-out hover:scale-105"
            >
              <div className="flex flex-col justify-center items-center group-hover/item:items-start group-hover/item:p-4">
                <div className=" w-[150px] laptop:w-[185px]  desktop-xl:w-[250px]  group-hover/item:w-[90px] group-hover/item:h-[90px]">
                  <img
                    src={chidapa}
                    alt=""
                    className="group-hover/item:rounded-[50%]"
                  />
                </div>
                <h3 className="font-bold mt-4 text-center text-base group-hover/item:text-[12px]">
                Chidapa Chanthong 
                </h3>
                <p className="hidden group-hover/item:block text-[12px] desktop-xl:text-base">
                As the Director of Global Business Development & Expansion at Techsauce, I'm a passionate leader driving Techsauce's mission to ignite technological and entrepreneurial progress in Southeast Asia. We connect innovators, business leaders, ecosystem builders, and policymakers through educational resources, community building, and strategic partnerships.
                </p>
                <p className="group-hover/item:hidden text-center ">
                Director of Global Business Development & Expansion
                </p>
                <p className="group-hover/item:hidden text-center">
                Techsauce 
                </p>
              </div>
            </div>

            {/* <div
              id="jenjira-mobile"
              className="flex flex-col justify-center items-center  group/item hover:bg-orange-primary w-48 hover:w-[20rem] min-[400px]:hover:w-[23rem] min-[500px]:hover:w-[27rem] hover:h-64 hover:justify-start hover:items-start transition-all"
            >
              <div className="flex flex-col justify-center items-center group-hover/item:items-start group-hover/item:p-4">
                <div className="h-[150px] w-[150px] group-hover/item:w-[90px] group-hover/item:h-[90px]">
                  <img
                    src={jenjira}
                    alt=""
                    className="group-hover/item:rounded-[50%]"
                  />
                </div>
                <h3 className="font-bold mt-4">Jenjira Namjatturas</h3>
                <p className="hidden group-hover/item:block">
                  Community Lead, Tech Thailand Advocates
                </p>
                <p className="group-hover/item:hidden">Community Lead, Tech</p>
                <p className="group-hover/item:hidden">Thailand Advocates</p>
              </div>
            </div> */}
          </div>
          <div className="laptop:hidden flex flex-col justify-center items-center "></div>
        </section>
        <section
          id="partner"
          className="py-4 px-8 tablet:py-8 tablet:px-12 bg-white"
        >
          <h2 className="font-bold text-lg desktop-xl:text-xl text-blue-primary">
            Partners
          </h2>
          <div className="flex flex-row justify-center items-center gap-4 tablet:gap-12 max-h-[10rem]">
            <div className="w-[33.33%] flex justify-center">
              <img
                src={techsauceLogo}
                alt=""
                className="scale-[0.6] tablet:scale-75"
              />
            </div>
            <div className="w-[33.33%] flex justify-center">
              <img src={thaiAccLogo} alt="" className="scale-100" />
            </div>
            <div className="w-[33.33%] flex justify-center">
              <img
                src={globaltechLogo}
                alt=""
                className="scale-90 tablet:scale-100"
              />
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
