import React from "react";
import { Link } from "react-router-dom";
import becomeAndAdvocates from "../../assets/images/become-and-advocate.png";
import Footer from "../../components/footer/footer";
export default function BecomeAndAdvocatesPage() {
  return (
    <div className="mobile:pt-[5.3rem] tablet:pt-[4.3rem] mobile:h-[100dvh] tablet:h-[100vh] overflow-scroll">
      <div className="flex flex-col items-center  bg-white min-h-[100vh] overflow-x-hidden">
        <div className="w-full">
          <img
            src={becomeAndAdvocates}
            alt=""
            className="mobile:h-24 tablet:h-auto w-full"
          />
        </div>
        <div className="text-center mobile:p-3 min-[390px]:p-6 tablet:p-20">
          <h1 className="text-orange-primary font-bold">
            How Do I Become a Tech Thailand Advocate?
          </h1>
          <p className="text-start mt-4">
            Tech Thailand Advocates is an open, inclusive community.
          </p>
          <p className="text-start mt-4">
            The advocates include founders, investors, and global tech leaders,
            as well as students, coders, policymakers, teachers, and service
            providers.
          </p>
          <p className="text-start mt-4">
            Tech Thailand Advocates members are recruited through referrals or
            direct introductions to founder Oranuch Lerdsuwankij. However, if
            you contact us through the website, or attend one of our events, she
            will be in touch with you to confirm your involvement and explain
            the vision, mission, and structure of Tech Thailand Advocates.
          </p>
          <p className="text-start mt-4">
            Being an Advocate requires no time commitment, and you can
            participate as much or as little as you like. No matter what level
            of engagement an Advocate has, there are three things they need to
            do:
          </p>
          <ul className="list-disc  text-orange-primary font-bold mt-4 text-start ml-6">
            <li>
              <h1>Communicate key messages about Thailand's tech sector</h1>
            </li>
            <li>
              <h1>
                Introduce an Advocate when you are ready to expand the network
              </h1>
            </li>
            <li>
              <h1>
                Ensuring the development of a strong and vibrant technology
                sector by helping one another for the 'greater good'
              </h1>
            </li>
          </ul>
          <p className="text-start mt-4">
            How Do I Become a Tech Thailand Advocate?
          </p>
          <p className="text-start mt-4">
            To maximize the benefits of this group, we are looking for members
            who have plans that align with our vision and mission as well as the
            advocate's.
          </p>
          <p className="text-start mt-4">
            For more information, please contact us at{" "}
            <Link
              to="techthailandadvocates@techsauce.co"
              className="text-blue-600 hover:text-blue-400 transition-colors"
            >
              techthailandadvocates@techsauce.co
            </Link>
          </p>
          <div className="mt-8">
            <a href="https://form.typeform.com/to/j7erI3b1" className="bg-orange-primary text-white p-4 font-bold w-32 uppercase hover:bg-orange-800 transition-colors duration-300">
              join us
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
