import React from "react";
import initiative1 from "../../assets/images/initiative-1.jpg";
import initiative2 from "../../assets/images/initiative-2.jpg";
import initiative3 from "../../assets/images/initiative-3.jpg";
import Footer from "../../components/footer/footer";
import "./initiative-page.css";
export default function InitiativePage() {
  return (
    <div className="mobile:pt-[5.3rem] tablet:pt-[4.3rem] mobile:h-[100dvh] tablet:h-[100vh] overflow-scroll">
      <section
        id="net-zero"
        className="grid mobile:grid-cols-1 tablet:grid-cols-12 bg-white"
      >
        <div className="col-span-6  ">
          <img src={initiative1} alt="" className="h-60 tablet:h-full w-full" />
        </div>
        <div className="col-span-6 text-dark-primary bg-polygon mr-8 mb-8 pt-8 pl-8 tablet:pl-14 tablet:pt-16 pb-20 ">
          <h1 className="text-3xl font-bold uppercase ">Net zero</h1>
          <br />
          <div>
            <p>
              Net zero is becoming increasingly crucial for Thai society as the
              country strives to balance economic growth with environmental
              sustainability.
            </p>
            <br />

            <p>
              In Thailand, the effects of climate change are becoming
              increasingly noticeable, with frequent natural disasters such as
              floods and droughts affecting communities and causing significant
              economic damage. Adopting a net zero approach to energy production
              and consumption can help reduce the country's carbon footprint,
              reducing the impact of these events and protecting the livelihoods
              of those living in affected areas.
            </p>
          </div>
        </div>
      </section>
      <section className="grid mobile:grid-cols-1 tablet:grid-cols-12 bg-white">
        <div className="col-span-6 tablet:hidden">
          <img src={initiative2} alt="" className="h-60 tablet:h-full w-full" />
        </div>

        <div className="col-span-6 text-dark-primary bg-polygon mx-8 mb-8 pt-8 tablet:pt-16  pb-20">
          <p>
            In addition, the transition to net zero energy systems can also have
            a positive impact on the economy. The development and implementation
            of renewable energy technologies can create new job opportunities
            and stimulate investment in the energy sector, while reducing the
            country’s dependence on fossil fuels.
          </p>
          <br />
          <p>
            Furthermore, by setting a net zero target, Thailand can position
            itself as a leader in sustainable development in the region and help
            raise awareness about the importance of addressing the climate
            crisis. This will not only benefit the country, but will also
            contribute to global efforts to tackle climate change and ensure a
            sustainable future for future generations.
          </p>
        </div>
        <div className="col-span-6 hidden tablet:block ">
          <img src={initiative2} alt="" className="h-60 tablet:h-full w-full" />
        </div>
      </section>
      <section className="grid mobile:grid-cols-1 tablet:grid-cols-12 bg-white">
        <div className="col-span-6 bg-initiative-second">
          <img src={initiative3} alt="" className="h-60 tablet:h-full w-full" />
        </div>
        <div className="col-span-6 text-dark-primary bg-polygon mr-8 mb-8 pt-8 pl-8 pb-24">
          <p>
            In conclusion, the transition to a net zero society is not only a
            necessary step for Thailand in addressing the impacts of climate
            change, but it also represents a tremendous opportunity for the
            country to drive economic growth, create jobs, and secure a
            sustainable future.
          </p>
          <br />
          <p>
            By engaging stakeholders through the Tech Thailand Advocates, we can
            help raise awareness about the importance of net zero and the role
            that businesses can play in achieving this goal.{" "}
          </p>
          <br />

          <p>
            By advocating for policies that support the transition to a
            low-carbon economy, we can help create an enabling environment for
            Thailand to achieve its net zero goals.
          </p>
          <br />

          <p>
            By advocating for policies that support the transition to a
            low-carbon economy, we can help create an enabling environment for
            Thailand to achieve its net zero goals.
          </p>
          <br />

          <p>
            In summary, there are many ways in which TTC and our community can
            help Thailand achieve its net zero goals, from promoting energy
            efficiency and investing in renewable energy to engaging with
            stakeholders and advocating for policy change. By taking a proactive
            approach, we can help make a meaningful contribution to a
            sustainable future for Thailand and the planet.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}
