import React from "react";

import "./App.css";
import Navbar from "./components/navbar/navbar";
import HomePage from "./page/homepage/home-page";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InitiativePage from "./page/initiative-page/initiative-page";
// import Footer from "./components/footer/footer";
import BecomeAndAdvocatesPage from "./page/become-and-advocates-page/become-and-advocates-page";
import PrivacyPolicyPage from "./page/privacy-policy-page/privacy-policy-page";
import ContactPage from "./page/contact/contact-page";
function App() {
  return (
    <div className="App">
   
     <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/initiative" element={<InitiativePage />}></Route>
          <Route path="/privacy" element={<PrivacyPolicyPage />}></Route>
          <Route
            path="/become-and-advocate"
            element={<BecomeAndAdvocatesPage />}
          ></Route>
          <Route path="/contact" element={<ContactPage />}></Route>

        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    
    </div>
  );
}

export default App;
