import React from "react";
import Footer from "../../components/footer/footer";
import { Widget } from '@typeform/embed-react'
import "./contact.css";
export default function ContactPage() {
  return (
    <div className="mobile:pt-[5.3rem] tablet:pt-[4.6rem] mobile:h-[100dvh] tablet:h-[100vh] overflow-scroll">
      <div className="h-[80vh] tablet:h-[100vh] bg-white">
        <div className="h-[80vh] tablet:h-[100vh] p-8 tablet:p-16  bg-contact tablet:mx-4 flex flex-col  items-center">
          <h1 className="text-center font-bold text-2xl tablet:text-3xl text-orange-primary h-[5%]">
            Contact us
          </h1>
          <div className="flex flex-row justify-center items-center  mt-8 h-[80%] w-full">
          <Widget id="PjpD1rrE"  className="my-form w-4/5 h-[100%]" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
