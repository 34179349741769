import React from "react";
import Footer from "../../components/footer/footer";

export default function PrivacyPolicyPage() {
  return (
    <div className="mobile:pt-[5.3rem] tablet:pt-[4.3rem] mobile:h-[100dvh] tablet:h-[100vh] overflow-scroll">
      <div
        id="policy"
        className="policy min-h-[100vh] px-4 py-8  tablet:p-14 bg-white"
      >
        <h1 className="font-bold">PRIVACY NOTICE</h1> <br />
        <p>
          Techsauce Media Co., Ltd. (the “Company” or “we”) recognizes the
          importance of the protection and maintaining privacy and security of
          your Personal Data (“you”) that provided to us when you purchase our
          products or services, provide your information during the registration
          process, the use of the websites operated by the Company or when you
          participate in our events or programs. We, therefore, established this
          Privacy Notice (“Privacy Notice”) to describe how we collect, use and
          disclose your Personal Data and how we handle and protect such
          personal data to be in accordance with the Personal Data Protection
          Act B.E. 2562 (2019) (“PDPA”) and any applicable personal data
          protection laws.
        </p>
        <br />
        <p>
          We reserve the right to modify this Privacy Notice from time to time,
          so please review this Privacy Notice periodically to stay informed
          about this Privacy Notice before you provide any of your Personal Data
          to the Company. Any changes to this Privacy Notice will become
          effective when we post the revised Privacy Notice on our website or
          communication channels. In case any modification deprives your rights
          or privacy in relation to this Privacy Notice, the Company will
          provide additional notice of significant updates on the Privacy Notice
          and will first obtain your consent for any additional processing
          activities of your Personal Data that we did not explain under this
          Privacy Notice, except as otherwise permitted by law.
        </p>{" "}
        <br />
        <p>
          “Personal Data” means any information relating to an individual
          person, which enables the identification of such person, whether
          directly or indirectly, but not including the information of the
          deceased person.
        </p>{" "}
        <br />
        <h1 className="font-bold">Sources of Personal Data</h1> <br />
        <p>
          The Company may collect the Personal Data that receive directly from
          you through the following actions:
        </p>{" "}
        <br />
        <ul className="list-decimal ml-8">
          <li>
            When you provide your information to us during the registration
            process for participation in our programs or events.
          </li>
          <li>
            When you provide your information or documents to us for the
            agreement or contract preparation process or for service and product
            discussion.
          </li>
          <li>
            When you make a payment to us regarding the use of our products or
            services.
          </li>
          <li>
            When we seek information about you and receive your Personal Data in
            connection with your relationship with us.
          </li>
          <li>
            When you communicate with us for support or queries via our contact
            channels such as telephone, email, or other applicable channels you
            can reach us.
          </li>
          <li>
            When you submit you article, comments or testimonial on our website
            or the provided channels.
          </li>
          <li>
            When you visit the website operated by us and submit your
            information to us.
          </li>
        </ul>{" "}
        <br />
        <p>
          In addition, we may also collect your Personal Data from the other
          available sources that are not directly from you, such as:
        </p>{" "}
        <br />
        <ul className="list-decimal ml-8">
          <li>
            A social media platform where you publicize your Personal Data, such
            as Facebook.
          </li>
          <li>
            Search engines or any public sources available on the internet.
          </li>
          <li>
            Our business partners that you willingly provide your Personal Data
            and you allow such business partners or third parties to disclose
            your Personal Data.
          </li>
          <li>
            Government authorities or any public sources that are available by
            government agencies.
          </li>
        </ul>{" "}
        <br />
        <h1 className="font-bold">Types of Collected Personal Data</h1> <br />
        <p>
          The Company shall collect Personal Data within the purpose, scope,
          lawful and fair methods as is necessary which is stipulated in the
          scope of the Company’s business operation and related to the purpose
          of providing products or services or assisting you with the matter
          related to our business operation. The Personal Data that the Company
          collects shall be included but not be limited to:
        </p>{" "}
        <br />
        <ul className="list-disc ml-8">
          <li>
            <b>Identification data</b> e.g., name, surname, signature, photos,
            identification number, tax identification number, passport number,
            motion picture, voice record.
          </li>
          <li>
            <b>Contact data</b> e.g., mailing address, telephone number, email
            address (including the email address you use to log in to the
            account on our website).
          </li>
          <li>
            <b>Proof of identity</b> e.g., copy of identification card, copy of
            passport.
          </li>
          <li>
            <b>Occupation data</b> e.g., position title, company name.
          </li>
          <li>
            <b>Financial data</b> e.g., bank account information, transaction
            details.
          </li>
          <li>
            <b>Technical data</b> e.g., IP address, MAC address, Cookie ID,
            activity log.
          </li>
          <li>
            <b>Other relevant data</b> e.g., any information that you
            voluntarily submitted to us that contained information that is
            considered to be Personal Data such as article, testimonials,
            feedback, comments, interests, information that appeared on any
            submitted documents related to the participation in our program or
            events used of our websites, etc.
          </li>
        </ul>{" "}
        <br />
        <p>
          In certain circumstances, we may collect, use or disclose your
          Personal Data that is specially categorized as sensitive personal data
          under PDPA, such as health data, religion, biometric data (such as
          facial recognition data, iris recognition data, fingerprint
          recognition data) and any data which may affect the data subject, you,
          in the same manner as sensitive personal data. However, we will take
          appropriate measures in protecting and using this kind of data only if
          necessary for us to pursue the purpose specified under this Privacy
          Notice as permissible under PDPA or we have obtained your explicit
          consent prior to the collection or use of your sensitive personal
          data.
        </p>{" "}
        <br />
        <p>
          If you fail to provide us with your Personal Data which is necessary
          for us to use for providing services or products to you, such as your
          name-surname and tax identification number, we may not be able to
          provide you with our services or products or may not proceed with your
          request for information about our products or services.
        </p>{" "}
        <br />
        <p>
          If you provide the Personal Data of your personnel, employee,
          colleague, team members, staff, contractor, contact person or any
          person that will act on your behalf for the purposes set forth in this
          Privacy Notice, it is your responsibility to inform them of their
          rights with respect to the contents of this Privacy Notice as
          applicable to them. You are also responsible for obtaining any
          required consent from them and ensuring that you have the right to
          provide their Personal Data to us.
        </p>{" "}
        <br />
        <h1 className="font-bold">Purposes of Processing</h1> <br />
        <p>
          The processing of your Personal Data as indicated in this Privacy
          Notice is for the following purposes:
        </p>{" "}
        <br />
        <ul className="list-disc ml-8">
          <li>
            To use your information for consideration in entering into an
            agreement with you or for entering into an agreement regarding the
            use of our products or services, and to send and receive further
            information or document between you and the Company.
          </li>
          <li>
            To perform any obligations we have with you under the agreement or
            contract.
          </li>
          <li>
            To proceed with your request before purchasing our services or
            products or participating in our programs or events.
          </li>
          <li>
            To prepare information about products, services, programs, or events
            that you are interested.
          </li>
          <li>
            To proceed with your payment, including issuing quotations and
            invoices or any related document.
          </li>
          <li>
            To analyze and verify the identity and profile before allowing you
            to participate in our programs or events.
          </li>
          <li>
            To conduct business planning, reporting and for any internal
            management purposes.
          </li>
          <li>
            To manage our relationship and to support you in case you face any
            issues from using our services or products or during your
            participation in our programs or events.
          </li>
          <li>
            To provide you with information about programs or events for which
            you have registered or are interested, such as updates, and possible
            changes, cancellations, or similar information.
          </li>
          <li>
            To analyze and assess your information for preparing the news,
            information or offers relating to our products, services events or
            program in which you may be interest.
          </li>
          <li>
            To inform you of the benefits, promotions, marketing activities, and
            invitations of our activities including offering any products and
            services that you may be interested in via the channels you provided
            to us.
          </li>
          <li>
            To improve our services, marketing, customer relationships and
            experiences by analyze your preferences, interests, and behavior in
            order to provide you with tailored content and the most relevant
            content and communications.
          </li>
          <li>
            To comply with any applicable law or regulatory requirements that
            the Company has a duty to comply with.
          </li>
        </ul>{" "}
        <br />
        <h1 className="font-bold">
          Legal Basis for Processing Your Personal Data
        </h1>{" "}
        <br />
        <p>
          The Company will process your Personal Data where there is a legal
          basis to do so. In accordance with the PDPA requirements and
          provisions, we will lawfully process your Personal Data by relying on
          the following legal basis:
        </p>{" "}
        <br />
        <ul className="list-disc ml-8">
          <li>
            It is necessary for the Company to take steps at your request prior
            to entering into a contract and to perform contractual obligations
            between you and the Company;
          </li>
          <li>
            It is necessary for the legitimate interests of the Company or any
            other persons or juristic persons, except where such interests are
            overridden by fundamental rights of your Personal Data;
          </li>
          <li>
            when the processing is necessary for the performance of a task
            carried out in the public interest by the Company, or it is
            necessary for the exercising of official authority vested in the
            Company;
          </li>
          <li>
            It is necessary for the Company to comply with any applicable laws;
            and
          </li>
          <li>
            When the Company obtains your consent or explicit consent (as
            required by law).
          </li>
        </ul>{" "}
        <br />
        <h1 className="font-bold">Storage of Personal Data</h1> <br />
        <p>
          We will retain your Personal Data in document and electronic form. We
          store your Personal Data on our company’s server in Thailand and cloud
          system provided by our affiliate company and third party outside
          Thailand.
        </p>{" "}
        <br />
        <h1 className="font-bold">Cross-border Data Transfer</h1> <br />
        <p>
          For the purpose of providing services or products to you, we may be
          required to disclose or transfer your Personal Data to our affiliate
          company or group company that locate outside Thailand. If we do so, we
          will implement security measures to ensure that your Personal Data is
          transferred to the destination country that provides adequate
          safeguard or other procedures as required by law to protect and
          maintain your Personal Data, and to take steps to ensure that no
          unauthorized or unlawful processing of your Personal Data. In
          addition, we will ensure you that the recipient of your Personal Data
          will only access your Personal Data and process it for the purposes of
          providing you services or products or for pursuing the purposes
          specified under this Privacy Notice.
        </p>{" "}
        <br />
        <h1 className="font-bold">Advertising and Marketing</h1> <br />
        <p>
          We may send certain information or newsletter for the purpose of
          offering an interesting offer to you via the email that you provide to
          us during your use of our services or products for the purpose of
          offering products or services that you may be interested in. If you no
          longer wish to receive communications from us via email, you can click
          the “unsubscribe” link in the email or contact us via our email.
        </p>{" "}
        <br />
        <p>
          We do not use automated decision-making without human intervention,
          including profiling in a way that may have significant effects on you.
        </p>{" "}
        <br />
        <h1 className="font-bold">Cookies</h1> <br />
        <p>
          Cookies are small files that a site or its service provider transfers
          to your computer’s hard drive through your web browser (if you allow)
          that enables the site’s or service provider’s systems to recognize
          your browser and capture and remember certain information. They are
          also used to help us understand your preferences based on previous or
          current site activity, which enables us to provide you with improved
          services. We also use cookies to help us compile aggregate data about
          site traffic and interaction to offer better site experiences and
          tools in the future.
        </p>{" "}
        <br />
        <p>
          If you disable cookies, some features will be disabled. It will turn
          off some of the features that make your site experience more
          efficient, and some of our services will not function properly.
        </p>{" "}
        <br />
        <p>
          If you would like to learn more about the use of cookies on website
          operated by us and setting such cookies, you can visit
          https://techthailandadvocates.org/privacy-policy/ or you can find
          details on how to reject or delete cookies as well as general
          additional information about cookies that can be found at
          www.allaboutcookies.org.
        </p>{" "}
        <br />
        <h1 className="font-bold">Disclosure of Personal Data</h1> <br />
        <p>
          In certain circumstances, we may disclose your Personal Data for the
          purposes specified under this Privacy Notice to the following parties:
        </p>{" "}
        <br />
        <h1 className="font-bold">Affiliates and Group Company</h1> <br />
        <p>
          We may disclose your Personal Data to our affiliate company or group
          company for the purposes specified under this Privacy Notice, such as
          disclosing to our affiliate or group company for the purpose specified
          under this Privacy Notice.
        </p>{" "}
        <br />
        <h1 className="font-bold">Service Providers</h1> <br />
        <p>
          We may use service providers to assist us in any activities related to
          purposes specified under this Privacy Notice such as banks or
          financial institutions, payments gateway providers, ticketing system
          providers, marketing agencies, courier service providers and
          information technology (IT) service providers, business consultants
          and tax and legal counsel. Please note that each service provider has
          its own privacy notice that will govern the processing of your
          Personal Data. In addition, if our service providers have acted as
          data processors under PDPA, we will conclude a data processing
          agreement with such service providers to limit the scope of their
          processing activities and responsibilities they have regarding your
          Personal Data.
        </p>{" "}
        <br />
        <h1 className="font-bold">Law Enforcement</h1> <br />
        <p>
          Under certain circumstances, we may be required to disclose your
          Personal Data if required by law or in response to valid requests by
          government authorities such as Revenue Department for tax purposes or
          any government authorities that we are obliged to disclose your
          Personal Data.
        </p>{" "}
        <br />
        <h1 className="font-bold">Business Partners</h1> <br />
        <p>
          We may disclose certain Personal Data to business partners to
          coordinate and provide our products or services to you and provide the
          necessary information. In certain circumstances, we may disclose your
          Personal Data to our business partners or sponsors that may match
          business funding interests with you in order to proceed with your
          request to participate in our programs.
        </p>{" "}
        <br />
        <h1 className="font-bold">Business Transfer</h1> <br />
        <p>
          In connection with any reorganization, restructuring, merger or
          acquisition, or other transfer of assets, we may transfer information,
          including certain of your Personal Data, provided that the receiving
          party agrees to respect your Personal Data in a manner that is
          consistent with this Privacy Notice and the PDPA.
        </p>{" "}
        <br />
        <h1 className="font-bold">Retention period of Personal Data</h1> <br />
        <p>
          We will retain your Personal Data for as long as necessary for the
          purposes set out in this Privacy Notice or while you have a
          relationship with us as our customer, service users, and participants
          of our programs or events unless any applicable laws require or permit
          a longer retention period. However, we may maintain your information
          for the purpose due to internal retention procedures. We will erase,
          destroy, or anonymize your Personal Data when it is no longer
          necessary for providing our services or products to you or you no
          longer require our support for the use of services or products, or
          when the period is permitted by applicable laws has lapsed.
        </p>{" "}
        <br />
        <h1 className="font-bold">Data Subject Rights</h1> <br />
        <p>
          When you provide your Personal Data to us, you have the rights as a
          data subject under PDPA which you can exercise your rights free of
          charge, under the exception of the law.
        </p>{" "}
        <br />
        <p>
          Subject to the PDPA thereof, you may exercise any of these rights:
        </p>{" "}
        <br />
        <ul className="list-decimal ml-8">
          <li>
            Right to withdraw consent: If you have given consent to us to
            collect, use or disclose your Personal Data whether before or after
            the effective date of the Personal Data Protection Laws, you have
            the right to withdraw such consent at any time throughout the period
            your Personal Data available to us.
          </li>
        </ul>{" "}
        <br />
        <p>
          However, the withdrawal of consent shall not affect the processing of
          Personal Data you have already given consent to us legally.
        </p>{" "}
        <br />
        <ul className="list-decimal ml-8">
          <li>
            <b>Right to access</b>: You have the right to access your Personal
            Data that is under our responsibility; to request us to make a copy
            of such data for you; and to request us to reveal how we obtain your
            Personal Data.
          </li>
          <li>
            <b>Right to data portability</b>: You have the right to obtain your
            Personal Data if we organize such Personal Data in automatic
            machine-readable or usable format and can be processed or disclosed
            by automatic means; to request us to send or transfer the Personal
            Data in such format directly to other data controllers if doable by
            automatic means; and to request to obtain the Personal Data in such
            format sent or transferred by us directly to other data controllers
            unless not technically feasible.
          </li>
          <li>
            <b>Right to objection</b>: You have the right to object to the
            collection, use or disclosure of your Personal Data at any time if
            such doing is conducted for legitimate interests of us, corporation
            or individual or for carrying out public tasks unless such
            collection, use or disclosure is within your reasonable expectation.
          </li>
          <li>
            <b>Right to be forgotten</b>: You have the right to request us to
            erase, destroy or anonymize your Personal Data if you believe that
            the collection, use or disclosure of your Personal Data is against
            relevant laws; or retention of the data by us is no longer necessary
            in connection with related purposes under this Privacy Notice; or
            when you request to withdraw your consent or to object to the
            processing as earlier described.
          </li>
          <li>
            <b>Right to restriction of processing</b>: You have the right to
            request us to suspend processing your Personal Data during the
            period where we examine your rectification or objection request; or
            when it is no longer necessary, and we must erase or destroy your
            Personal Data pursuant to relevant laws, but you request us to
            suspend the processing instead.
          </li>
          <li>
            <b>Right to rectification</b>: You have the right to rectify your
            Personal Data to be updated, complete and not misleading.
          </li>
          <li>
            <b>Right to lodge a complaint</b>: You have the right to complain to
            competent authorities pursuant to relevant laws if you believe that
            the collection, use or disclosure of your Personal Data is violating
            or not in compliance with relevant laws.
          </li>
        </ul>{" "}
        <br />
        <p>
          You may exercise these rights as the data subject under the PDPA by
          contacting us or our Data Protection Officer (DPO) as mentioned below.
          We will notify the result of your request within 30 days upon receipt
          of such valid request. If we deny the request, we will inform you of
          the reason via SMS, email address, telephone, or registered mail (if
          applicable).
        </p>{" "}
        <br />
        <h1 className="font-bold">Data Security Measure</h1> <br />
        <p>
          We recognize the importance of maintaining the security of your
          Personal Data. Therefore, we endeavor to protect your Personal Data by
          establishing security measures in accordance with the principles of
          confidentiality, integrity, and availability to prevent the loss,
          unauthorized or unlawful access, destruction, use, alteration, or
          disclosure, including administrative safeguards, technical safeguard,
          physical safeguard and access controls. In addition, our staff,
          employees, business partners and service providers are obliged to
          maintain the confidentiality of your Personal Data by complying with
          the data security standards and policies when any of your Personal
          Data is being used, transmitted, transferred or processed.
        </p>{" "}
        <br />
        <h1 className="font-bold">Data Breach Notification</h1> <br />
        <p>
          In case there is data breach incident occurred in our database, we
          will notify the Office of the Personal Data Protection Committee
          without delay and, where feasible, within 72 hours after having become
          aware of it. If the Personal Data breach is likely to result in a high
          risk to your rights and freedoms of you, we will also notify the
          detail of the personal data breach and the remedial measures to you
          without delay through our website, SMS, email address, telephone or
          registered mail (if applicable).
        </p>{" "}
        <br />
        <h1 className="font-bold">Contact Information</h1> <br />
        <p>
          If you have any questions about this Privacy Notice or would like to
          exercise your rights, you can contact us by using the following
          details:
        </p>{" "}
        <br />
        <h1 className="font-bold">Techsauce Media Co., Ltd.</h1> <br />
        <p>
          Address: Techsauce Media Co.,Ltd. (Head Office) 9/173 Soi Santhonburi
          29/2, Bangwa, Phasi-Charoen,
          <br /> Bangkok 10160
          <br />
          Email: techthailandadvocates@techsauce.co
          <br />
          Tel: 02-001-5375
          <br />
          Website:{" "}
          <span>
            <a href="https://techthailandadvocates.org/">
              {" "}
              https://techthailandadvocates.org/
            </a>
          </span>
        </p>{" "}
        <br />
        <h1 className="font-bold">Data Protection Officer (DPO)</h1> <br />
        <p>
          Email: dpo-ts@techsauce.co
          <br />
          Tel: 02-001-5375
        </p>{" "}
        <br />
        <p className="text-sm">
          This Privacy Notice was last updated and effective on 8 February 2023
        </p>
      </div>
      <Footer />
    </div>
  );
}
